import CloseMenu from "..//Assets/close-menu.svg";
import { NavLink, useLocation, Link } from "react-router-dom";
// import { useState, useEffect } from "react";
import Line from "..//Assets/nav-line.svg";
import LGLine from "..//Assets/LGline.svg";
import { BsTwitter } from "react-icons/bs";
import { BsLinkedin } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";

const MobileMenu = ({ onClose }) => {

  // active dynamic link
  const location = useLocation();

  const { pathname } = location;

  const splitLocation = pathname.split("/");

  return (
    <>
        <div className="bg-buttonColor pt-16 pr-5 fixed top-0 left-0 z-999 h-[700px] w-full">
          <div className="flex flex-row justify-end gap-[.65rem]">
            <button className="bg-buttonFont rounded-md font-inter text-sm font-semibold leading-5 tracking-[-0.26px] px-9">
            <Link to="https://play.google.com/store/apps/details?id=com.liquidmoni.liquidmoni" target="_blank"> 
             Download Now
              </Link>
            </button>
            <img
              src={CloseMenu}
              alt="Close icon"
              className="cursor-pointer"
              onClick={onClose}
            />
          </div>

          <div className="mt-[2.8rem] pl-6">
            <h1 className="text-white text-base font-normal leading-[120%] uppercase">
              Menu
            </h1>
            <img src={Line} alt="" className="mt-4 mb-6" />
            <nav className="text-white inline-flex flex-col gap-6 pb-40">
              <NavLink to="/">Send Money</NavLink>
              <img src={LGLine} alt="" />
              <NavLink to="/about-us" className={`${
                splitLocation[1] === "about-us" ? "text-Purple" : " "
              } text-white`}>About</NavLink>
              <img src={LGLine} alt="" />
              {/* <NavLink to="/contact-us" className={`${
                splitLocation[1] === "contact-us" ? "text-Purple" : " "
              } text-white`}>Contact</NavLink> */}
              <img src={LGLine} alt="" />
              <NavLink to="/faq" className={`${
                splitLocation[1] === "faq" ? "text-Purple" : " "
              } text-white`}>FAQ</NavLink>
              <img src={LGLine} alt="" />
              <NavLink to="/support" className={`${
                splitLocation[1] === "support" ? "text-Purple" : " "
              } text-white`}>Support</NavLink>
              <img src={LGLine} alt="" />

              <img src={Line} alt="" className="mt-[4.25rem]" />

              <ul className="flex flex-row justify-between">
              <NavLink to="https://twitter.com/Liquidmoniapp" target="_blank" className="hover:cursor-pointer hover:-translate-y-[3px]">
                <BsTwitter />
              </NavLink>
              <NavLink to="https://www.linkedin.com/company/liquidmoni/about/" target="_blank" className="hover:cursor-pointer hover:-translate-y-[3px]">
                <BsLinkedin />
              </NavLink>
              <NavLink to="https://instagram.com/liquidmoniapp?igshid=Y2IzZGU1MTFhOQ==" target="_blank" className="hover:cursor-pointer hover:-translate-y-[3px]">
                <BsInstagram />
              </NavLink>
              {/* <li>
                <BsGithub />
              </li> */}
            </ul>
            </nav>

          </div>
        </div>
    </>
  );
};

export default MobileMenu;
