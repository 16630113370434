import Line from "..//Assets/Line.svg";
import MobileHero from "..//Assets/mobile-hero.svg";
import BigHero from "..//Assets/hero-img.png";
import Button from "./Button";
import "firebase/database";

const Hero = () => {

  return (
    <>
      <main className="flex flex-col lg:pl-20 lg:pr-0 lg:flex-row">
        <section className="mt-12 px-6 lg:mt-[16rem] 2xl:mt-[22rem] 2xl:ml-[8.5rem] 6xl:mt-[25rem]">
          <h1 className="text-center text-darkPurple text-3xl font-semibold tracking-[-1.28px] font-inter mb-6 lg:text-left lg:text-5xl xl:w-[600px]">
            Virtual Transaction Mechanism
          </h1>
          <p className="text-center text-gray600 font-inter text-base font-normal mb-4 lg:text-left lg:text-lg lg:mb-8 lg:w-[540px]">
            The Liquidmoni App makes it easy for you to receive and send money
            in your local currency from anywhere in the world.
          </p>

          <Button />

          <div className="flex justify-center gap-2 mt-4 lg:mt-16 lg:justify-start 2xl:justify-start">
            <h2 className="text-buttonColor text-4xl font-medium font-jakarta lg:text-5xl">
              200
            </h2>
            <p className="text-[10px] text-buttonColor font-jakarta font-normal pt-1 lg:w-[77px] lg:font-semibold">
              <span className="mb-2 block">Recognized</span>
              <span className="mt-2 block">Countries</span>
            </p>

            <img src={Line} alt="Line icon" />

            <h2 className="text-buttonColor text-4xl font-medium font-jakarta lg:text-5xl">
              120+
            </h2>
            <p className="text-[10px] text-buttonColor font-jakarta font-normal lg:font-semibold w-[83px] lg:w-[105px]">
              <span className="mb-2 block pt-1">Currencies</span>
              <span className="lg:mt-2 lg:block">around the Globe</span>
            </p>
          </div>
        </section>

        <section className="pr-0 ml-auto md:m-auto lg:ml-[-18rem] lg:mt-[10rem] xl:mt-[7rem] xl:ml-[-22rem] "  id="hero-img">
          <img src={MobileHero} alt="" className="lg:hidden" />
          <img src={BigHero} alt="" className="hidden lg:block md:w-[2000px]" />
        </section>
      </main>
    </>
  );
};

export default Hero;
