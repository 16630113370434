import NavBar from "../Components/NavBar";
import Hero from "../Components/Hero";
import LandingAbout from "../Components/HomePage/LandingAbout";
import Location from "../Components/Location";
import Transactions from "../Components/Transactions";
import Subscription from "../Components/Subscription";
// import LandingFaq from "../Components/LandingFaq";
import Footer from "../Components/Footer";

const Home = () => {
  return (
    <>
      <div className="bg-heroImg">
        <NavBar />
        <Hero />
      </div>
      <LandingAbout />
      <Location />
      <Transactions />
      <Subscription />
      {/* <LandingFaq /> */}
      <Footer />
    </>
  );
};

export default Home;
