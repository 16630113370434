import { useMediaQuery } from 'react-responsive';
import { useState } from "react";
import HeroImg from "..///../Assets/aboutHero.svg";
import MobileImg from '..///../Assets/aboutHeroMobile.svg';

const AboutHero = () => {
  const isSmallScreen = useMediaQuery({ maxWidth: 390});

  const [userData, setUserData] = useState({
    email: "",
  });

  let name, value;

  const handleInputChange = (e) => {
    name = e.target.name;
    value = e.target.value;
    setUserData({ ...userData, [name]: value });
  };

  const handleSubmit = async (e) => {
    const { email } = userData;
    e.preventDefault();
    const option = {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        email,
      }),
    };

    const result = await fetch(
      "https://liquidmoni-fe58b-default-rtdb.firebaseio.com/subscription-form.json",
      option
    );
    console.log(result);

    if (result.ok) {
      setUserData({
        email: "",
      });
    }
  };

  return (
    <>
      <main>
        <div className="xl:px-[22rem] lg:pt-[8rem] px-6">
          <h1 className="text-darkPurple text-[32px] tracking-[-1.26px] font-inter font-semibold text-center mt-12 lg:text-6xl xl:leading-[72px] xl:tracking-[-2.4px] ">Bridging the gap of international transaction</h1>
          <p className="text-gray600 mt-6 text-sm leading-[26px] font-inter font-normal text-center lg:text-lg xl:leading-7 lg:mt-6 xl:w-[547px] xl:m-auto">
            We are an international payment gateway bridging gaps of
            international transaction between senders and receivers within
            remitting approval location.
          </p>
          <form className="relative sm:mx-[4rem] md:mx-[8rem] lg:mx-0 lg:text-center lg:mt-10 mt-4" onSubmit={handleSubmit}>
            <input
              type="email"
              onChange={(e) => handleInputChange(e)}
          name="email"
          value={userData.email}
              placeholder="Enter email address"
              className="rounded-lg border-2 border-solid border-[#A1A1AA] p-2 w-full placeholder:text-buttonColor placeholder:font-inter placeholder:pl-2 lg:w-[476px] lg:p-6 lg:rounded-2xl 2xl:w-[540px] bg-transparent"
            />
            <button className="absolute right-[.3rem] top-[.2rem] rounded-lg bg-buttonColor text-buttonFont p-2 font-inter text-sm font-bold w-[122px] lg:right-[17.5rem] xl:right-[4.1rem] lg:top-[.7rem] lg:p-3 lg:w-[179px] lg:text-lg 1xl:right-[7rem] xxl:right-[11rem] 2xl:right-[28rem] 3xl:right-[12rem] 6xl:right-[28rem]" id="about_button">
              Get Notified
            </button>
          </form>
        </div>

        <img src={isSmallScreen ? MobileImg : HeroImg} alt="Hero" className="lg:pl-[6rem] lg:mt-8 mt-4 2xl:pl-[25rem] 3xl:pl-[12rem] 6xl:pl-[22rem]" />
        {/* <img src={isLargeScreen ? WalletLg : Wallet} alt="Multiple Currency" className="rounded-md" /> */}

      </main>
    </>
  );
};

export default AboutHero;
