import React from "react";
import BorderBottom1 from "../../Assets/border-bottom.svg";
import BorderBottom from "../../Assets/border-bottom.svg";

const LandingAbout = () => {
  return (
    <>
      <section className="mt-24 px-6 md:pb-28 lg:px-24 2xl:px-[15.5rem]">
        <article className="flex flex-col md:text-center lg:flex-row lg:gap-[6rem] lg:text-left 2xl:gap-[23rem] 3xl:gap-[2.4rem]">
          <h1 className="text-2xl font-inter font-semibold tracking-[-0.96px] leading-[120%] lg:text-[35px] lg:w-[488px]">
            “We provide virtual transaction mechanism that meets your need”
          </h1>
          <p className="text-sm font-inter font-normal leading-[150%] text-neutralGray mt-8 lg:text-lg lg:w-[670px] lg:mt-0">
            Liquidmoni provides you with the greatest exchange rate, free money
            transfers, and instant payment to your local banks. Our support
            staff is available around the clock(24/7) to address any question or
            concerns, and we value your feedback and comment. We have highly
            skilled compliance and cyber security team.
          </p>
        </article>

        <article className="bg-Purple relative mt-8 rounded-xl px-8 pt-[54px] pb-16 flex flex-col sm:mx-[5rem] md:mx-0 md:mt-24 md:h-[218px] lg:px-[1rem] lg:flex-row 2xl:px-16 6xl:px-32">

            <div className="xl:ml-[2rem] lg:ml-[.5rem] 3xl:ml-[-0.7rem]">
            <h2 className="text-white font-inter text-[32px] leading-[66px] font-normal lg:text-[2.1rem]">Security</h2>
            <p className="text-white font-inter text-sm leading-[28px] mb-6 lg:text-lg lg:mt-6">Reliable fund transfer</p>
            <img src={BorderBottom1} alt="Line" className="md:transform md:rotate-90 md:h-[3px] md:ml-[-2rem] custom:ml-[-1rem] xl:ml-[5rem] lg:ml-[.6rem] 2xl:ml-[8rem] 3xl:ml-[5rem]" id="line"  />
            </div>
            

            <div className="pt-[60px] md:pt-0 md:absolute md:left-[14rem] custom:left-[17rem] lg:left-[17rem] xl:left-[25rem] 2xl:left-[35rem] 3xl:left-[25rem] 6xl:left-[35rem]" id="about_charge">
            <h2 className="text-white font-inter text-[32px] leading-[66px] font-normal lg:text-[2.1rem]">Zero charge</h2>
            <p className="text-white font-inter text-sm leading-[28px] mb-6 lg:text-lg lg:mt-6">Make payment at no cost</p>
            <img src={BorderBottom} alt="Line" className="md:transform md:rotate-90 md:h-[3px] md:ml-[.5rem] custom:ml-[2rem] lg:ml-[3rem] xl:ml-[4rem] 2xl:ml-[14rem] 3xl:ml-[5rem] 6xl:ml-[10rem]" id="line_charge" />
            </div>


            <div className="pt-[60px] md:pt-0 md:absolute md:left-[29rem] custom:left-[35rem] lg:left-[35rem] xl:left-[46rem] 2xl:left-[69rem] 3xl:left-[47rem] 6xl:left-[70rem]" id="about_transfer">
            <h2 className="text-white font-inter text-[32px] leading-[66px] font-normal lg:text-[2.1rem]">Instant Transfer</h2>
            <p className="text-white font-inter text-sm leading-[28px] lg:text-lg lg:mt-6">Accessible to everyone</p>
            </div>
        </article>
      </section>
    </>
  );
};

export default LandingAbout;
