import React from "react";
import BarCode from '..//Assets/scanbarcode.svg';
import EmptyWallet from '..//Assets/emptywallettick.svg';
import Bank from '..//Assets/bank.svg';
import LocationMap from '..//Assets/location.svg';
import LargeLocation from '..//Assets/Map.svg';

const Location = () => {
  return (
    <>
      <section className="mt-28 bg-[#F6F6F6]" id="send_money">
        <h1 className="text-center text-base font-inter font-extrabold tracking-[-0.2px] text-darkBlack pt-20 lg:pt-32">
          Say hello to <span className="text-Yellow">Liquidmoni</span>
        </h1>
        <p className="text-2xl font-inter font-normal tracking=[-1.2px] leading-[150%] px-6 text-center mt-6 xl:px-64 lg:text-black200 lg:text-4xl lg:font-semibold"> 
          Send and Receive up to <span className="text-Yellow">$5,000</span> daily from anywhere:
          Whether you're a freelancer, small business owner, or nonprofit
          organization.
        </p>

        <hr className="bg-[#E4E4E7] mt-12 mb-8 lg:mx-[4rem] xl:mx-64" />

        <div className="flex flex-row text-center px-[.2rem] lg:px-[4rem] xl:px-64 items-center justify-center sm:gap-[2.5rem] md:gap-[2rem] lg:text-left xl:gap-7 2xl:ml-[22rem] 3xl:ml-[2rem] 6xl:gap-[18rem]">
            <div className="lg:inline-flex">
                <img src={BarCode} alt="Bar Code Icon" className="m-auto lg:w-[34px] lg:h-[34px] lg:m-0" />
                <p className="text-black200 font-medium text-sm leading-[150%] font-inter lg:text-lg lg:ml-2 lg:mt-[.2rem] lg:h-[28px] lg:w-[294px] ">Share your Liquidmoni details</p>
            </div>

            <div className="lg:inline-flex">
                <img src={EmptyWallet} alt="Empty Wallet Icon" className="m-auto lg:w-[34px] lg:h-[34px]" />
                <p className="text-black200 font-medium text-sm leading-[150%] font-inter lg:text-lg lg:ml-3 lg:mt-[.2rem] lg:h-[28px] lg:w-[284px]">Send money in any currency</p>
            </div>

            <div className="lg:inline-flex">
                <img src={Bank} alt="Bank Icon" className="m-auto lg:w-[34px] lg:h-[34px]" />
                <p className="text-black200 font-medium text-sm leading-[150%] font-inter lg:text-lg lg:ml-3 lg:mt-[.2rem] lg:h-[28px] lg:w-[304px]">Receive money in any currency</p>
            </div>
        </div>

        <img src={LocationMap} alt="Location" className="mt-12 lg:hidden m-auto" />
        <img src={LargeLocation} alt="Location Map" className="hidden lg:block lg:px-64 lg:mt-10 2xl:m-auto 2xl:mt-16" />

      </section>
    </>
  );
};

export default Location;
