import React from "react";
import { useMediaQuery } from "react-responsive";
import MobileIphone from "..//Assets/IphoneMobile.png";
import IphoneLg from '..//Assets/iPhone 13.png';
import CheckCirlce from "..//Assets/check-circle.svg";
// import Button from "./Button";

const Subscription = () => {
  const isLargeScreen = useMediaQuery({ minWidth: 768 });

  return (
    <>
      <section className="mt-[3.25rem] pt-24 bg-buttonFont flex flex-col xl:flex-row relative flex-wrap pb-16 2xl:mt-[10rem]">
        <article className="lg:pl-[7rem] 2xl:pl-[15rem]">
          <h1 className="text-center font-inter text-black text-2xl font-semibold leading-[150%] tracking-[-0.2px] mb-4 lg:text-[45px] lg:text-left lg:w-[698px] lg:mb-12">
            What are you waiting for, get notified when we go live
          </h1>
          <p className="text-center text-gray600 font-inter text-sm font-medium leading-[150%] px-2 lg:text-xl lg:text-left lg:w-[655px]">
            With our virtual transaction mechanism, you can receive payments in
            your local currency, eliminating the need for costly currency
            conversions and enabling you to manage your finances more
            efficiently.
          </p>

          <div className="hidden lg:block mt-5">
            <ul className="flex flex-col">
              <li className="inline-flex text-black text-xl font-inter font-normal leading-[150%] mb-7">
                <img
                  src={CheckCirlce}
                  alt="Check circle Icon"
                  className="mr-3"
                />{" "}
                No hidden fees or conversion costs
              </li>
              <li className="inline-flex text-black text-xl font-inter font-normal leading-[150%] mb-7">
                <img
                  src={CheckCirlce}
                  alt="Check circle Icon"
                  className="mr-3"
                />{" "}
                24/7 customer support
              </li>
              <li className="inline-flex text-black text-xl font-inter font-normal leading-[150%]">
                <img
                  src={CheckCirlce}
                  alt="Check circle Icon"
                  className="mr-3"
                />{" "}
                Seamless integration with other apps
              </li>
            </ul>
          </div>

          <form className="relative mt-16 hidden lg:block">
            <input
              type="email"
              placeholder="Enter email address"
              className="rounded-lg border-2 border-solid border-[#A1A1AA] p-2 w-full placeholder:text-buttonColor placeholder:font-inter placeholder:pl-2 lg:w-[476px] lg:p-4 lg:rounded-2xl 2xl:w-[540px]"
            />
            <button className="absolute right-[.3rem] top-[.2rem] rounded-lg bg-buttonColor text-buttonFont p-2 font-inter text-sm font-bold w-[122px] lg:right-[14.7rem] lg:top-[.5rem] lg:py-2 lg:px-[.6rem] lg:w-[179px] lg:text-lg 2xl:right-[10.5rem] hover:bg-Purple">
              Get Notified
            </button>
          </form>
        </article>

        <article className="lg:mb-[35rem]">
          <img
            src={isLargeScreen ? IphoneLg : MobileIphone}
            alt="Iphone"
            id="iphone_lg"
            className="m-auto lg:absolute xl:left-[31rem] xl:top-[-2rem] lg:w-[824px] xl:w-[770px] xxl:left-[43rem] 2xl:left-[65rem] 3xl:left-[45rem] 6xl:left-[70rem]"
          />
          {/* <img src={MobileIphone} alt="Lady Smiling" className="m-auto" /> */}
        </article>
      </section>
    </>
  );
};

export default Subscription;
