import React, { useState } from "react";
// import firebase from 'firebase/app';
import "firebase/database";
// import {ref, push, child, update} from 'firebase/database';

const ContactForm = () => {

  // const [isSubmitted, setIsSubmitted] = useState(false);

  const [userData, setUserData] = useState({
    name: "",
    email: "",
    subject: "",
    number: "",
    message: "",
  });

  let name, value;

  const handleInputChange = (e) => {
    name = e.target.name;
    value = e.target.value;
    setUserData({ ...userData, [name]: value });
  };

  const handleSubmit = async (e) => {
    const { name, email, subject, number, message } = userData;
    e.preventDefault();
    const option = {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        name,
        email,
        subject,
        number,
        message,
      }),
    };
    const result = await fetch(
      "https://liquidmoni-fe58b-default-rtdb.firebaseio.com/contact-us.json",
      option
    );
    console.log(result);

    if (result.ok) {
      setUserData({
        name: "",
        email: "",
        subject: "",
        number: "",
        message: "",
      });
    }
  };

  return (
    <>
      <form className="ml-[1rem]" onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Your name"
          value={userData.name}
          onChange={(e) => handleInputChange(e)}
          name="name"
          className="rounded-md py-5 pl-6 placeholder:font-inter placeholder:text-lg placeholder:font-medium placeholder:leading-6 placeholder:text-gray300 w-[315px] mb-[2rem] lg:mb-0"
          id="name"
        />

        <input
          type="email"
          placeholder="Email Address"
          value={userData.email}
          onChange={(e) => handleInputChange(e)}
          name="email"
          className="rounded-md py-5 pl-6 placeholder:font-inter placeholder:text-lg placeholder:font-medium placeholder:leading-6 placeholder:text-gray300 lg:ml-10 w-[315px]"
          id="email"
        />

        <input
          type="text"
          placeholder="Subject"
          value={userData.subject}
          onChange={(e) => handleInputChange(e)}
          name="subject"
          className="rounded-md py-5 pl-6 placeholder:font-inter placeholder:text-lg placeholder:font-medium placeholder:leading-6 placeholder:text-gray300 w-[315px] mt-[2.6rem] mb-[2rem] lg:mb-0"
          id="subject"
        />

        <input
          type="text"
          placeholder="Contact Number"
          value={userData.number}
          onChange={(e) => handleInputChange(e)}
          name="number"
          className="rounded-md py-5 pl-6 placeholder:font-inter placeholder:text-lg placeholder:font-medium placeholder:leading-6 placeholder:text-gray300 w-[315px] lg:ml-10"
          id="number"
        />

        <textarea
          placeholder="How can we help?"
          value={userData.message}
          onChange={(e) => handleInputChange(e)}
          name="message"
          className="lg:w-[670px] w-[300px] rounded-md mt-[2.6rem] pl-6 pb-[7.6rem] pt-6 placeholder:font-inter placeholder:text-lg placeholder:font-medium placeholder:leading-6 placeholder:text-gray300"
          id="message"
        ></textarea>

        <button
          className="rounded-md bg-buttonColor text-buttonFont px-4 py-3 font-inter font-bold text-base w-[172px] block mt-7 lg:ml-[31rem]"
          type="submit"
        >
          Submit
        </button>
      </form>

    </>
  );
};

export default ContactForm;
