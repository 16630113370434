import { NavLink, useLocation, Link } from "react-router-dom";
import { useEffect, useState } from "react";
// import Logo from "..//Assets/mobile-logo.svg";
import Logo from "..//Assets/adminlogo.svg";
import MenuBar from "..//Assets/menu.svg";
import MobileMenu from "./MobileMenu";

const NavBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [navChange, setNavChange] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 120) {
        setNavChange(true);
      } else {
        setNavChange(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleMenuClick = () => {
    setIsMenuOpen((prevState) => !prevState);
  };

  const handleMobileMenuClose = () => {
    setIsMenuOpen(false);
  };

  // active dynamic link
  const location = useLocation();

  const { pathname } = location;

  const splitLocation = pathname.split("/");

  const pageUp = () => {
    window.scrollTo({ top: (0, 0), behavior: 'smooth' });
  };

  const handleNavLinkClick = (event) => {
    event.preventDefault();
    const section = document.getElementById('send_money');
    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: 'smooth',
      });
    }
  };

  return (
    <>
      <header className={`${navChange ? 'bg-white opacity-[0.95] pt-0 pb-0' : ''} fixed transition-all duration-300 ease-in-out flex px-6 xl:pl-[6.25rem] pt-11 pb-6 justify-between overflow-hidden lg:justify-between lg:pl-[100px] lg:z-[999] lg:gap-[3rem] xl:gap-[19rem] 1xl:gap-[31rem]  2xl:gap-[62rem] 2xl:pl-[15rem] 3xl:gap-[27rem] 6xl:gap-[60rem]`}>
        <NavLink to="/">
          <img
            src={Logo}
            alt="Liquidmoni Logo"
            onClick={pageUp}
          />
        </NavLink>
        <img
          src={MenuBar}
          alt="Hamburger Icon"
          className="lg:hidden hover:cursor-pointer"
          onClick={handleMenuClick}
        />

        <nav className="bg-white p-[1rem] rounded-tl-full rounded-bl-full hidden lg:block lg:w-[857px] 1xl:w-[600px] xxl:w-[605px] no-underline">
          <div className="flex items-center">
            <a
              href="/#send_money"
              className={`${
                splitLocation[1] === "" ? "active:text-Purple" : " "
              } pr-10 font-inter text-darkPurple font-bold text-[16px] no-underline`}
            id="nav-link"
            onClick={handleNavLinkClick}>
              Send Money
            </a>
            <NavLink
              to="/about-us"
              id="nav-link"
              onClick={pageUp}
              className={`${
                splitLocation[1] === "about-us" ? "text-Purple" : " "
              } pr-10 font-inter text-darkPurple font-bold text-[16px] no-underline`}
            >
              About
            </NavLink>
            {/* <NavLink
              to="/contact-us"
              id="nav-link"
              onClick={pageUp}
              className={`${
                splitLocation[1] === "contact-us" ? "text-Purple" : " "
              } pr-10 font-inter text-darkPurple font-bold text-[16px] no-underline`}
            >
              Contact
            </NavLink> */}
            <NavLink to="/faq" id="nav-link" className="pr-10 font-inter text-darkPurple  font-bold text-[16px] no-underline" onClick={pageUp}>
              FAQ
            </NavLink>
            <NavLink
              to="/support"
              id="nav-link"
              onClick={pageUp}
              className={`${
                splitLocation[1] === "support" ? "text-Purple" : " "
              } pr-10 font-inter text-darkPurple font-bold text-[16px] no-underline`}
            >
              Support
            </NavLink>
            <button className="rounded-md bg-buttonColor text-buttonFont px-4 py-3 font-inter font-bold text-base ml-auto hover:bg-Purple">
              <Link to="https://play.google.com/store/apps/details?id=com.liquidmoni.liquidmoni" target="_blank"> 
             Download Now
              </Link>
            </button>
          
          </div>
        </nav>
        {isMenuOpen && <MobileMenu onClose={handleMobileMenuClose} />}
      </header>
    </>
  );
};

export default NavBar;
