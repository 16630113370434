import React from "react";
import NavBar from "../Components/NavBar";
import EmailIcon from "..//Assets/email-icon.svg";
import PhoneIcon from "..//Assets/call-icon.svg";
import ContactForm from "../Components/ContactForm";
import Footer from "../Components/Footer";

const Support = () => {
  return (
    <main className="bg-cream">
      <NavBar />
      <section className="lg:pt-[10rem] lg:px-[5.2rem] pb-[4.3rem] 2xl:px-[15rem]">
        <h1 className="text-black200 font-inter lg:text-[40px] font-bold leading-[120%] lg:tracking-[-1.6px]">
          We will love to hear from you.
        </h1>
        <p className="mt-6 text-neutralGray lg:text-lg font-normal leading-[150%] lg:w-[569px]">
          Contact us if you have any questions about our company or product. Let
          us know any queries, comments or recommendations via the form below.
        </p>

        <div className="mt-9">
          <div className="flex flex-col xl:flex-row gap-[9rem] 1xl:gap-[2rem] 2xl:gap-[18rem] 3xl:gap-[6rem]" id="form">
            <div>
              <div className="flex gap-[1.8rem]">
                <img src={EmailIcon} alt="Email icon" />
                <div>
                  <h2 className="font-inter text-lg font-bold leading-9 text-black400">
                    Email Us
                  </h2>
                  <p className="text-gray200 text-lg font-medium leading-[150%] w-[250px] lg:w-[334px]">
                    Ask us a question by email and we will respond within a few
                    days.
                  </p>
                </div>
              </div>

              <div className="flex gap-[1.8rem] mt-6">
                <img src={PhoneIcon} alt="Phone icon" />
                <div>
                  <h2 className="font-inter text-lg font-bold leading-9 text-black400">
                    Call any time
                  </h2>
                  <p className="text-gray200 text-lg font-medium leading-[150%] ">
                    Call us if your question requires an immediate response.
                  </p>
                </div>
              </div>
            </div>

            <ContactForm />
          </div>
        </div>
      </section>

      <Footer />
    </main>
  );
};

export default Support;
