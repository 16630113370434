import { React, useState } from "react";
import { Link } from "react-router-dom";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Footer";

const FAQ = () => {
  const [activeTab, setActiveTab] = useState("Product");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const renderContent = () => {
    switch (activeTab) {
      case "Product":
        return (
          <div className="flex gap-[3.75rem]">
            <div>
              <div>
                <h2 className="text-lg font-medium font-inter leading-7 text-gray900">
                  How does Liquidmoni work?
                </h2>
                <p className="font-inter text-base font-normal leading-7 text-gray-500">
                  Liquidmoni is the simplest way for immigrants to send and
                  receive payments to and from anywhere in the world. With
                  Liquidmoni, you get virtual accounts in (China, EUR, GBP,
                  Ghana) that can accept international transfers.
                </p>
              </div>

              <div className="mt-6">
                <h2 className="text-lg font-medium font-inter leading-7 text-gray900">
                  What is a Liquidpay tag?
                </h2>
                <p className="font-inter text-base font-normal leading-7 text-gray-500">
                  These tags are unique identifiers that replace account numbers
                  on Liquidmoni. With them, you can receive or send money to any
                  Liquidmoni user for free. For example, with your Liquidpay
                  tag, a friend in another continent gets to send money to you
                  instantly with no transfer charges.
                </p>
              </div>

              <div className="mt-6">
                <h2 className="text-lg font-medium font-inter leading-7 text-gray900">
                  What countries can I send money to?
                </h2>
                <p className="font-inter text-base font-normal leading-7 text-gray-500">
                  Currently, you can send money to China, UK, and many more at a
                  low fee.
                </p>
              </div>

              <div className="mt-6">
                <h2 className="text-lg font-medium font-inter leading-7 text-gray900">
                  Are there charges for sending or receiving money into my
                  foreign virtual account?
                </h2>
                <p className="font-inter text-base font-normal leading-7 text-gray-500">
                  While there are no charges for receiving money into your
                  foreign account.
                </p>
              </div>
            </div>
            <div>
              <div>
                <h2 className="text-lg font-medium font-inter leading-7 text-gray900">
                  What are the exchange rates?
                </h2>
                <p className="font-inter text-base font-normal leading-7 text-gray-500">
                  At Liquidmoni, we strive to offer competitive exchange rates.
                  At the time of purchasing or selling a foreign currency with
                  your local currency, the rates are always lower than the
                  prevailing market rates.
                </p>
              </div>

              <div className="mt-6">
                <h2 className="text-lg font-medium font-inter leading-7 text-gray900">
                  What IDs can I sign up on Liquidpay with?
                </h2>
                <p className="font-inter text-base font-normal leading-7 text-gray-500">
                  At Liquidmoni, we've made it easy to sign up using only your
                  phone number along with valid Identification cards. It is
                  important to sign up with valid identification cards to
                  prevent impersonation and also to keep you safe.
                </p>
              </div>

              <div className="mt-6">
                <h2 className="text-lg font-medium font-inter leading-7 text-gray900">
                  Are my funds secure in the virtual foreign accounts?
                </h2>
                <p className="font-inter text-base font-normal leading-7 text-gray-500">
                  Yes, your funds are very secure in your virtual account.
                </p>
              </div>
            </div>
          </div>
        );
      case "Accounts":
        return (
          <div className="flex gap-[3.75rem]">
            <div>
              <div>
                <h2 className="text-lg font-medium font-inter leading-7 text-gray900">
                  How does Liquidmoni work?
                </h2>
                <p className="font-inter text-base font-normal leading-7 text-gray-500">
                  Liquidmoni is the simplest way for immigrants to send and
                  receive payments to and from anywhere in the world. With
                  Liquidmoni, you get virtual accounts in (China, EUR, GBP,
                  Ghana) that can accept international transfers.
                </p>
              </div>

              <div className="mt-6">
                <h2 className="text-lg font-medium font-inter leading-7 text-gray900">
                  What is a Liquidpay tag?
                </h2>
                <p className="font-inter text-base font-normal leading-7 text-gray-500">
                  These tags are unique identifiers that replace account numbers
                  on Liquidmoni. With them, you can receive or send money to any
                  Liquidmoni user for free. For example, with your Liquidpay
                  tag, a friend in another continent gets to send money to you
                  instantly with no transfer charges.
                </p>
              </div>

              <div className="mt-6">
                <h2 className="text-lg font-medium font-inter leading-7 text-gray900">
                  What countries can I send money to?
                </h2>
                <p className="font-inter text-base font-normal leading-7 text-gray-500">
                  Currently, you can send money to China, UK, and many more at a
                  low fee.
                </p>
              </div>

              <div className="mt-6">
                <h2 className="text-lg font-medium font-inter leading-7 text-gray900">
                  Are there charges for sending or receiving money into my
                  foreign virtual account?
                </h2>
                <p className="font-inter text-base font-normal leading-7 text-gray-500">
                  While there are no charges for receiving money into your
                  foreign account.
                </p>
              </div>
            </div>
            <div>
              <div>
                <h2 className="text-lg font-medium font-inter leading-7 text-gray900">
                  What are the exchange rates?
                </h2>
                <p className="font-inter text-base font-normal leading-7 text-gray-500">
                  At Liquidmoni, we strive to offer competitive exchange rates.
                  At the time of purchasing or selling a foreign currency with
                  your local currency, the rates are always lower than the
                  prevailing market rates.
                </p>
              </div>

              <div className="mt-6">
                <h2 className="text-lg font-medium font-inter leading-7 text-gray900">
                  What IDs can I sign up on Payday with?
                </h2>
                <p className="font-inter text-base font-normal leading-7 text-gray-500">
                  At Liquidmoni, we've made it easy to sign up using only your
                  phone number along with valid Identification cards. It is
                  important to sign up with valid identification cards to
                  prevent impersonation and also to keep you safe.
                </p>
              </div>

              <div className="mt-6">
                <h2 className="text-lg font-medium font-inter leading-7 text-gray900">
                  Are my funds secure in the virtual foreign accounts?
                </h2>
                <p className="font-inter text-base font-normal leading-7 text-gray-500">
                  Yes, your funds are very secure in your virtual account.
                </p>
              </div>
            </div>
          </div>
        );
      case "Payments":
        return (
          <div className="flex gap-[3.75rem]">
            <div>
              <div>
                <h2 className="text-lg font-medium font-inter leading-7 text-gray900">
                  How does Liquidmoni work?
                </h2>
                <p className="font-inter text-base font-normal leading-7 text-gray-500">
                  Liquidmoni is the simplest way for immigrants to send and
                  receive payments to and from anywhere in the world. With
                  Liquidmoni, you get virtual accounts in (China, EUR, GBP,
                  Ghana) that can accept international transfers.
                </p>
              </div>

              <div className="mt-6">
                <h2 className="text-lg font-medium font-inter leading-7 text-gray900">
                  What is a Liquidpay tag?
                </h2>
                <p className="font-inter text-base font-normal leading-7 text-gray-500">
                  These tags are unique identifiers that replace account numbers
                  on Liquidmoni. With them, you can receive or send money to any
                  Liquidmoni user for free. For example, with your Liquidpay
                  tag, a friend in another continent gets to send money to you
                  instantly with no transfer charges.
                </p>
              </div>

              <div className="mt-6">
                <h2 className="text-lg font-medium font-inter leading-7 text-gray900">
                  What countries can I send money to?
                </h2>
                <p className="font-inter text-base font-normal leading-7 text-gray-500">
                  Currently, you can send money to China, UK, and many more at a
                  low fee.
                </p>
              </div>

              <div className="mt-6">
                <h2 className="text-lg font-medium font-inter leading-7 text-gray900">
                  Are there charges for sending or receiving money into my
                  foreign virtual account?
                </h2>
                <p className="font-inter text-base font-normal leading-7 text-gray-500">
                  While there are no charges for receiving money into your
                  foreign account.
                </p>
              </div>
            </div>
            <div>
              <div>
                <h2 className="text-lg font-medium font-inter leading-7 text-gray900">
                  What are the exchange rates?
                </h2>
                <p className="font-inter text-base font-normal leading-7 text-gray-500">
                  At Liquidmoni, we strive to offer competitive exchange rates.
                  At the time of purchasing or selling a foreign currency with
                  your local currency, the rates are always lower than the
                  prevailing market rates.
                </p>
              </div>

              <div className="mt-6">
                <h2 className="text-lg font-medium font-inter leading-7 text-gray900">
                  What IDs can I sign up on Payday with?
                </h2>
                <p className="font-inter text-base font-normal leading-7 text-gray-500">
                  At Liquidmoni, we've made it easy to sign up using only your
                  phone number along with valid Identification cards. It is
                  important to sign up with valid identification cards to
                  prevent impersonation and also to keep you safe.
                </p>
              </div>

              <div className="mt-6">
                <h2 className="text-lg font-medium font-inter leading-7 text-gray900">
                  Are my funds secure in the virtual foreign accounts?
                </h2>
                <p className="font-inter text-base font-normal leading-7 text-gray-500">
                  Yes, your funds are very secure in your virtual account.
                </p>
              </div>
            </div>
          </div>
        );
      case "Cards and transactions":
        return (
          <div className="flex gap-[3.75rem]">
            <div>
              <div>
                <h2 className="text-lg font-medium font-inter leading-7 text-gray900">
                  How does Liquidmoni work?
                </h2>
                <p className="font-inter text-base font-normal leading-7 text-gray-500">
                  Liquidmoni is the simplest way for immigrants to send and
                  receive payments to and from anywhere in the world. With
                  Liquidmoni, you get virtual accounts in (China, EUR, GBP,
                  Ghana) that can accept international transfers.
                </p>
              </div>

              <div className="mt-6">
                <h2 className="text-lg font-medium font-inter leading-7 text-gray900">
                  What is a Liquidpay tag?
                </h2>
                <p className="font-inter text-base font-normal leading-7 text-gray-500">
                  These tags are unique identifiers that replace account numbers
                  on Liquidmoni. With them, you can receive or send money to any
                  Liquidmoni user for free. For example, with your Liquidpay
                  tag, a friend in another continent gets to send money to you
                  instantly with no transfer charges.
                </p>
              </div>

              <div className="mt-6">
                <h2 className="text-lg font-medium font-inter leading-7 text-gray900">
                  What countries can I send money to?
                </h2>
                <p className="font-inter text-base font-normal leading-7 text-gray-500">
                  Currently, you can send money to China, UK, and many more at a
                  low fee.
                </p>
              </div>

              <div className="mt-6">
                <h2 className="text-lg font-medium font-inter leading-7 text-gray900">
                  Are there charges for sending or receiving money into my
                  foreign virtual account?
                </h2>
                <p className="font-inter text-base font-normal leading-7 text-gray-500">
                  While there are no charges for receiving money into your
                  foreign account.
                </p>
              </div>
            </div>
            <div>
              <div>
                <h2 className="text-lg font-medium font-inter leading-7 text-gray900">
                  What are the exchange rates?
                </h2>
                <p className="font-inter text-base font-normal leading-7 text-gray-500">
                  At Liquidmoni, we strive to offer competitive exchange rates.
                  At the time of purchasing or selling a foreign currency with
                  your local currency, the rates are always lower than the
                  prevailing market rates.
                </p>
              </div>

              <div className="mt-6">
                <h2 className="text-lg font-medium font-inter leading-7 text-gray900">
                  What IDs can I sign up on Payday with?
                </h2>
                <p className="font-inter text-base font-normal leading-7 text-gray-500">
                  At Liquidmoni, we've made it easy to sign up using only your
                  phone number along with valid Identification cards. It is
                  important to sign up with valid identification cards to
                  prevent impersonation and also to keep you safe.
                </p>
              </div>

              <div className="mt-6">
                <h2 className="text-lg font-medium font-inter leading-7 text-gray900">
                  Are my funds secure in the virtual foreign accounts?
                </h2>
                <p className="font-inter text-base font-normal leading-7 text-gray-500">
                  Yes, your funds are very secure in your virtual account.
                </p>
              </div>
            </div>
          </div>
        );
      case "International Payments":
        return (
          <div className="flex gap-[3.75rem]">
            <div>
              <div>
                <h2 className="text-lg font-medium font-inter leading-7 text-gray900">
                  How does Liquidmoni work?
                </h2>
                <p className="font-inter text-base font-normal leading-7 text-gray-500">
                  Liquidmoni is the simplest way for immigrants to send and
                  receive payments to and from anywhere in the world. With
                  Liquidmoni, you get virtual accounts in (China, EUR, GBP,
                  Ghana) that can accept international transfers.
                </p>
              </div>

              <div className="mt-6">
                <h2 className="text-lg font-medium font-inter leading-7 text-gray900">
                  What is a Liquidpay tag?
                </h2>
                <p className="font-inter text-base font-normal leading-7 text-gray-500">
                  These tags are unique identifiers that replace account numbers
                  on Liquidmoni. With them, you can receive or send money to any
                  Liquidmoni user for free. For example, with your Liquidpay
                  tag, a friend in another continent gets to send money to you
                  instantly with no transfer charges.
                </p>
              </div>

              <div className="mt-6">
                <h2 className="text-lg font-medium font-inter leading-7 text-gray900">
                  What countries can I send money to?
                </h2>
                <p className="font-inter text-base font-normal leading-7 text-gray-500">
                  Currently, you can send money to China, UK, and many more at a
                  low fee.
                </p>
              </div>

              <div className="mt-6">
                <h2 className="text-lg font-medium font-inter leading-7 text-gray900">
                  Are there charges for sending or receiving money into my
                  foreign virtual account?
                </h2>
                <p className="font-inter text-base font-normal leading-7 text-gray-500">
                  While there are no charges for receiving money into your
                  foreign account.
                </p>
              </div>
            </div>
            <div>
              <div>
                <h2 className="text-lg font-medium font-inter leading-7 text-gray900">
                  What are the exchange rates?
                </h2>
                <p className="font-inter text-base font-normal leading-7 text-gray-500">
                  At Liquidmoni, we strive to offer competitive exchange rates.
                  At the time of purchasing or selling a foreign currency with
                  your local currency, the rates are always lower than the
                  prevailing market rates.
                </p>
              </div>

              <div className="mt-6">
                <h2 className="text-lg font-medium font-inter leading-7 text-gray900">
                  What IDs can I sign up on Payday with?
                </h2>
                <p className="font-inter text-base font-normal leading-7 text-gray-500">
                  At Liquidmoni, we've made it easy to sign up using only your
                  phone number along with valid Identification cards. It is
                  important to sign up with valid identification cards to
                  prevent impersonation and also to keep you safe.
                </p>
              </div>

              <div className="mt-6">
                <h2 className="text-lg font-medium font-inter leading-7 text-gray900">
                  Are my funds secure in the virtual foreign accounts?
                </h2>
                <p className="font-inter text-base font-normal leading-7 text-gray-500">
                  Yes, your funds are very secure in your virtual account.
                </p>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <main className="bg-cream">
      <NavBar />
      <section className="lg:pt-[13rem] pr-[4.3rem]">
        <h1 className="text-center text-black200 lg:text-[40px] font-inter font-bold leading-[120%] tracking-[-1.6px]">
          Common questions & answers
        </h1>
        <div>
          <div className="lg:mt-[5.1rem] pl-[6.25rem] flex gap-36">
            <div className="flex flex-col gap-6">
              <Link
                className={`font-inter text-lg leading-7 ${
                  activeTab === "Product" && "font-bold"
                }`}
                onClick={() => handleTabClick("Product")}
              >
                Product
              </Link>
              <Link
                className={`font-inter text-lg leading-7 ${
                  activeTab === "Accounts" && "font-bold"
                }`}
                onClick={() => handleTabClick("Accounts")}
              >
                Accounts
              </Link>
              <Link
                className={`font-inter text-lg leading-7 ${
                  activeTab === "Payments" && "font-bold"
                }`}
                onClick={() => handleTabClick("Payments")}
              >
                Payments
              </Link>
              <Link
                className={`font-inter text-lg leading-7 w-[198px] whitespace-nowrap ${
                  activeTab === "Cards and transactions" && "font-bold whitespace-nowrap"
                }`}
                onClick={() => handleTabClick("Cards and transactions")}
              >
                Cards and transactions
              </Link>
              <Link
                className={`font-inter text-lg leading-7 whitespace-nowrap ${
                  activeTab === "International Payments" && "font-bold"
                }`}
                onClick={() => handleTabClick("International Payments")}
              >
                International Payments
              </Link>
            </div>
            <div>
              {renderContent()}
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </main>
  );
};

export default FAQ;

//  {/* <div>
//             <div>
//               <h2 className="text-lg font-medium font-inter leading-7 text-gray900">
//                 How does Liquidmoni work?
//               </h2>
//               <p className="font-inter text-base font-normal leading-7 text-gray-500">
//                 Liquidmoni is the simplest way for immigrants to send and
//                 receive payments to and from anywhere in the world. With
//                 Liquidmoni, you get virtual accounts in (China, EUR, GBP, Ghana)
//                 that can accept international transfers.
//               </p>
//             </div>

//             <div className="mt-6">
//               <h2 className="text-lg font-medium font-inter leading-7 text-gray900">
//                 What is a Liquidpay tag?
//               </h2>
//               <p className="font-inter text-base font-normal leading-7 text-gray-500">
//                 These tags are unique identifiers that replace account numbers
//                 on Liquidmoni. With them, you can receive or send money to any
//                 Liquidmoni user for free. For example, with your Liquidpay tag,
//                 a friend in another continent gets to send money to you
//                 instantly with no transfer charges.
//               </p>
//             </div>

//             <div className="mt-6">
//               <h2 className="text-lg font-medium font-inter leading-7 text-gray900">
//                 What countries can I send money to?
//               </h2>
//               <p className="font-inter text-base font-normal leading-7 text-gray-500">
//                 Currently, you can send money to China, UK, and many more at a
//                 low fee.
//               </p>
//             </div>

//             <div className="mt-6">
//               <h2 className="text-lg font-medium font-inter leading-7 text-gray900">
//                 Are there charges for sending or receiving money into my foreign
//                 virtual account?
//               </h2>
//               <p className="font-inter text-base font-normal leading-7 text-gray-500">
//                 While there are no charges for receiving money into your foreign
//                 account.
//               </p>
//             </div>
//           </div>

//           <div>
//             <div>
//               <h2 className="text-lg font-medium font-inter leading-7 text-gray900">
//                 What are the exchange rates?
//               </h2>
//               <p className="font-inter text-base font-normal leading-7 text-gray-500">
//                 At Liquidmoni, we strive to offer competitive exchange rates. At
//                 the time of purchasing or selling a foreign currency with your
//                 local currency, the rates are always lower than the prevailing
//                 market rates.
//               </p>
//             </div>

//             <div className="mt-6">
//               <h2 className="text-lg font-medium font-inter leading-7 text-gray900">
//                 What countries can I send money to?
//               </h2>
//               <p className="font-inter text-base font-normal leading-7 text-gray-500">
//                 Currently, money can only be sent from your Liquidmoni wallet to
//                 any local Nigerian bank account and
//               </p>
//             </div>

//             <div className="mt-6">
//               <h2 className="text-lg font-medium font-inter leading-7 text-gray900">
//                 What IDs can I sign up on Payday with?
//               </h2>
//               <p className="font-inter text-base font-normal leading-7 text-gray-500">
//                 At Liquidmonu, we've made it easy to sign up using only your
//                 phone number along with valid Identification cards. It is
//                 important to sign up with valid identification cards to prevent
//                 impersonation and also to keep you safe.
//               </p>
//             </div>

//             <div className="mt-6">
//               <h2 className="text-lg font-medium font-inter leading-7 text-gray900">
//                 Are my funds secure in the virtual foreign accounts?
//               </h2>
//               <p className="font-inter text-base font-normal leading-7 text-gray-500">
//                 Yes, your funds are very secure in your virtual account.
//               </p>
//             </div>
//           </div> */}
